import React, { useEffect } from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE } from 'config'

import { PRODUCTS } from 'lib/products/constants'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import { getStructuredBreadCrumbs } from 'lib/json-ld/getStructuredBreadCrumbs'
import { trackAtNeedAdConvertion } from 'lib/tracking'
import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'

import Content from './Content'

const pageTitle =
  'Direct Cremations and Funeral Plans - Arrange a meaningful funeral with Farewill'
const pageDescription = `With Farewill Funerals, you can arrange a funeral today, or plan ahead with a prepaid funeral plan. You can save ${formatPriceInPounds(
  CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE
)} compared to the average funeral in the UK.`

const FuneralOverview = (): React.ReactElement => {
  useEffect(() => {
    // We want to record an event each time soneone visits the page
    trackAtNeedAdConvertion()
  }, [])

  return (
    <Wrapper background={COLOR.WHITE}>
      <JSONLD data={[getStructuredBreadCrumbs({})]} />
      <DefaultLayout
        title={pageTitle}
        description={pageDescription}
        product={PRODUCTS.FUNERAL}
        headerColor={COLOR.WHITE}
        footerShowFuneralPlansLegalInformation
      >
        <Content />
      </DefaultLayout>
    </Wrapper>
  )
}

export default FuneralOverview
